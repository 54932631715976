import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['oneDay', 'manyDays']

  showDisable(event) {
    const eventValue = event.target.dataset.value
    if (eventValue === 'one_per_week') {
      document.querySelector('.js-many-days').classList.add('hidden')
      this.manyDaysTargets.forEach((el) => el.disabled = true)
      document.querySelector('.js-one-day').classList.remove('hidden')
      this.oneDayTarget.disabled = false
    } else if(eventValue === 'personalized') {
      document.querySelector('.js-one-day').classList.add('hidden')
      this.oneDayTarget.disabled = true
      document.querySelector('.js-many-days').classList.remove('hidden')
      this.manyDaysTargets.forEach((el) => el.disabled = false)
    } else {
      document.querySelector('.js-one-day').classList.add('hidden')
      this.oneDayTarget.disabled = true
      document.querySelector('.js-many-days').classList.add('hidden')
      this.manyDaysTargets.forEach((el) => el.disabled = true)
    }
  }
  
}
