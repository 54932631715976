import Choices from 'choices.js'
import tinymce from 'tinymce/tinymce'
import { defaults } from './tinymce.config'

document.addEventListener('turbolinks:load', event => {
  const elements = document.querySelectorAll('.js-choices')
  elements.forEach((el, i) => {
    const urlParams = new URLSearchParams(window.location.search)
    const institutionId = urlParams.get('institution_id')
    console.log(el)
    console.log(institutionId)
    if (el.value === institutionId) {
      let jsInstitutionName = document.querySelector('.js-institution-name')
      if (jsInstitutionName) jsInstitutionName.innerHTML = el.selectedOptions[0].text
    }
    let url = el.dataset.url
    let configChoices = {
      removeItemButton: true,
      noChoicesText: 'No hay opciones para elegir',
      noResultsText: 'No se han encontrado resultados',
      itemSelectText: 'Seleccionar',
      placeholder: true,
      placeholderValue: 'Búsqueda de resolución',
      addItems: true,
      searchResultLimit: 25,
      searchFields: ['label', 'value', 'customProperties.registered']
    }
    if (el.className.split(' ').includes('search') && url) {
      configChoices['classNames'] = {
        containerInner: 'choices__inner__custom inline-block align-top w-full bg-white truncate border-2 border-black',
        containerOuter: 'choices',
        placeholder: 'opacity-100'
      }
    }
    if (el.className.split(' ').includes('disabled')) {
      configChoices['classNames'][
        'containerInner'
      ] = `${configChoices['classNames']['containerInner']} border-select-disabled bg-select-disabled`
      configChoices['classNames'][
        'containerOuter'
      ] = `${configChoices['classNames']['containerOuter']} pointer-events-none`
    }
    const choicesDocuments = new Choices(el, configChoices)
    if (el.className.split(' ').includes('search') && url) {
      const handleSearch = async (event, choicesDocuments) => {
        try {
          const response = await fetch(url + '?search=' + event.detail.value, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
          const items = await response.json()
          const options = items.map(item => {
            return {
              value: item.id,
              label: item.name,
              customProperties: { registered: item.registered }
            }
          })
          choicesDocuments.setChoices(options, 'value', 'label', true)
        } catch (err) {
          console.error(err)
        }
      }
      el.addEventListener(
        'search',
        event => {
          handleSearch(event, choicesDocuments)
        },
        false
      )
    }

    el.addEventListener(
      'addItem',
      event => {
        const derived = el.dataset.derived
        if (derived) {
          tinymce.remove()
        }
        const info = { true: 'derived', false: 'derivedNotRegistered' }
        const selectedOption = event.detail
        // const registered = selectedOption.customProperties.registered
        const registered = JSON.parse(selectedOption.customProperties).registered

        const noteInfo = document.querySelector('.js-note-info')
        let target   = event.target;
        let counterRegistered = 0;
        let counterNotRegistered = 0;
        for (let i = 0; i < target.length; i++) {
          let obj = JSON.parse(target[i].dataset.customProperties);
          if (obj.registered) counterRegistered++;
          if (!obj.registered) counterNotRegistered++;
        }
        noteInfo.value = el.dataset[counterRegistered > 0 && counterNotRegistered > 0 ? 'derivedRegisteredNotRegistered' : info[registered]]
        if (derived) {
          let config = Object.assign({ target: noteInfo }, defaults)
          tinymce.init(config)
        }
      },
      false
    )

    el.addEventListener(
      'change',
      function (event) {
        console.log('🚀 ~ file: choices.js ~ line 84 ~ elements.forEach ~ event.target', event.target)
        if (event.target.length && event.target[0].innerText.length) {
          let jsInstitutionName = document.querySelector('.js-institution-name')
          if (jsInstitutionName) jsInstitutionName.innerHTML = event.target[0].innerText
        }
      },
      false
    )
  })
})

document.addEventListener('turbolinks:load', event => {
  const elements = document.querySelectorAll('.js-choices2')
  elements.forEach((el, i) => {
    let configChoices = {
      removeItemButton: true,
      noChoicesText: 'No hay opciones para elegir',
      noResultsText: 'No se han encontrado resultados',
      itemSelectText: 'Seleccionar',
      placeholder: true,
      placeholderValue: 'Búsqueda de resolución',
      addItems: true,
      searchResultLimit: 25,
      searchFields: ['label', 'value', 'customProperties.registered']
    }
    if (el.className.split(' ').includes('search')) {
      configChoices['classNames'] = {
        containerInner: 'choices__inner__custom inline-block align-top w-full bg-white truncate border-2 border-black',
        placeholder: 'opacity-100'
      }
    }
    const choicesDocuments = new Choices(el, configChoices)
    if (el.className.split(' ').includes('search')) {
      const handleSearch = async (event, choicesDocuments) => {
        try {
          const response = await fetch('?search=' + event.detail.value, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
          const items = await response.json()
          const options = items.map(item => {
            return {
              value: item.id,
              label: item.name,
              customProperties: { registered: item.registered }
            }
          })
          choicesDocuments.setChoices(options, 'value', 'label', true)
        } catch (err) {
          console.error(err)
        }
      }
      el.addEventListener(
        'search',
        event => {
          handleSearch(event, choicesDocuments)
        },
        false
      )
    }

    el.addEventListener(
      'addItem',
      function (event) {
        console.log(event)
        const info = { true: 'derived', false: 'derivedNotRegistered' }
        const selectedOption = event.detail
        // const registered = selectedOption.customProperties.registered
        const registered = JSON.parse(selectedOption.customProperties).registered

        document.querySelector('.js-note-info').value = el.dataset[info[registered]]
      },
      false
    )
  })
})
