import Rails from '@rails/ujs'
import { Controller } from "stimulus"


export default class extends Controller {

  static targets = ['nav','content']

  connect() {
    const classNav = 'nav-link'
    const items = this.navTarget.children
    this.navTarget.addEventListener('click', (e) => {
      const nav = e.target
      if( nav.classList.contains( classNav )){
        const target = nav.dataset.target
        this.showContentTabs( target )
        nav.classList.add('active')
        for(let item of items){
          if( item.classList.contains( classNav ) && item != nav ){
            item.classList.remove('active')
          }
        }
      }
    })
  }

  showContentTabs( target ){
    const content = this.contentTarget.children
    for( let pane of content ){
      if( pane.classList.contains('tab-pane') ){
        const id = pane.id
        id == target ? pane.classList.add('active') : pane.classList.remove('active')
      }
    }
  }
}