module.exports = {
    defaults: {
        base_url: process.env.ASSET_PATH + 'tinymce',
        content_css: false,
        skin: false,
        branding: false,
        plugins: 'preview code fullscreen link table advlist lists help image',
        menubar: 'view insert format table',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist preview | forecolor backcolor removeformat | pagebreak | charmap | image media link | ltr rtl fullscreen',
        toolbar_sticky: true,
        suffix: '.min',
        content_style: 'html { font-family: Roboto, sans-serif; line-height: 1.5; }',
        images_upload_url: '/uploads', // Ruta para manejar la carga de imágenes y documentos adjuntos
        automatic_uploads: true,
        file_picker_types: 'image pdf document file', // Ajusta este valor según tus necesidades
        images_reuse_filename: true
    }
}