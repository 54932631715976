import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["phone", "email"];
  fillPreviewPhone(event) {
    let i = parseInt(event.target.getAttribute("data-index"))
    if (i > 0) {
      this.phoneTargets[i].value = this.phoneTargets[i - 1].value
    }
    this.phoneTargets[i].focus()
  }
  fillPreviewEmail(event) {
    let index = parseInt(event.target.getAttribute("data-index"))
    for (let j = this.emailTargets.length - 1; j >= 0; j--) {
      if (j !== index && !this.emailTargets[j].className.split(" ").includes("notify_me")) {
        this.emailTargets[j].value = ""
      }
    }
    this.emailTargets[index].focus();
  }
}
