import Rails from '@rails/ujs'
import { Controller } from "stimulus"
import tinymce from 'tinymce'
import { defaults } from '../src/tinymce.config'

export default class extends Controller {

  static targets = ['text_area', 'radio_button']
  
  connect() { 
    this.radio_buttonTargets.forEach( el => {        
        if (el.checked){          
          this.setTemplate(el.value)
        }
      }
    )
  }
  
  get options(){
    return JSON.parse(this.element.getAttribute('data-options'))
  }

  update(event) {
    this.setTemplate(event.target.value)
  }
  
  setTemplate(id){
    tinymce.remove()
    const value = this.options[id].replaceAll('<br /> ','')
    this.text_areaTarget.value = value
    let config = Object.assign({ target: this.text_areaTarget }, defaults)
    tinymce.init(config) 
  }
  
}