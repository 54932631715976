import Rails from '@rails/ujs'
import pDebounce from 'p-debounce'

document.addEventListener('change', event => {
  if (!event.target.matches('.js-submit-on-click')) {
    return
  }

  Rails.fire(event.target.closest('form'), 'submit')
})

const railsFire = async event => {
  if (!event.target.matches('.js-submit-on-input')) {
    return
  }

  Rails.fire(event.target.closest('form'), 'submit')
}

const debounced = pDebounce(railsFire, 500)

document.addEventListener('input', event => {
  debounced(event)
})
