import { Controller } from "stimulus"
import tinymce from 'tinymce/tinymce'
import { defaults } from '../src/tinymce.config'
import 'tinymce/models/dom'

import 'tinymce/icons/default/icons'
import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/table';
import 'tinymce/plugins/image';

import 'tinymce/plugins/wordcount';

export default class extends Controller {

    connect () {
      let config = Object.assign({ target: this.element }, defaults)
      config.setup = (editor) => {editor.on('keyup', (e) => { this.deleteCharacterEspecial()}) }
      tinymce.init(config)
    }

    disconnect () {
      tinymce.remove()
    }

    deleteCharacterEspecial(){
      var editor = tinymce.get('complaint_note'); // use your own editor id here - equals the id of your textarea
      var content = editor.getContent();
      if(content.search('&deg;') > 0){
        content = content.replace(/(&deg;)*/g, '');
        editor.setContent(content);
        editor.selection.select(editor.getBody(), true);
        editor.selection.collapse(false);
        editor.focus();
      }
    }
    
}