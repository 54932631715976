import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["input", "count", "reSendText"];
  static values = { countdown: Number, expires_in: Number, interval: null };

  connect() {  
    this.expires_in = 30
    this.countTarget.textContent = this.stopwatchFormat(this.expires_in)
    this.toggleSpan(true)
    this.runInterval()
    this.element.querySelector("#sendok").style.display = 'none'
  }

  runInterval() {
    this.interval = setInterval(() => {
        if (this.expires_in === 0) { 
            this.toggleSpan(false)                  
            clearInterval(this.interval)                    
        } else {
            this.expires_in -= 1
            this.countdown = this.stopwatchFormat(this.expires_in)
            this.countTarget.textContent = this.countdown
        }
    }, 1000);
  }

  async resetCount(){
    this.expires_in = 30
    this.countTarget.textContent = this.stopwatchFormat(this.expires_in)
    this.toggleSpan(true)
    clearInterval(this.interval)
    this.runInterval()
    const resendCodeUrl = this.element.querySelector("#resend").getAttribute("data-resend-code-url");
    try {
      await fetch(resendCodeUrl);
      this.element.querySelector("#sendok").style.display = ''
    } catch (err) {
      console.log(err)
    }
  }

  toggleSpan(showCounter) {
    this.element.querySelector("#resend").hidden = showCounter
    this.element.querySelector("#counter").hidden = !showCounter
  }

  stopwatchFormat(time){
    return `00:${String(time).padStart(2, '0')}`
  }
}