import { Controller } from 'stimulus'
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['showhide', 'select']

  connect() {
    this.elementChoices = new Choices(this.selectTarget, { removeItemButton: true })

    //Ancho mínimo dinámico del input del componente Choice
    //this.elementChoices.containerOuter.element.style.width = '300px';//Select
    const inputElement = this.elementChoices.input.element
    inputElement.addEventListener('input', this.handleInputChange.bind(this))

    this.change_level_sub_level()
  }

  handleInputChange(event) {
    const inputElement = this.elementChoices.input.element
    if (event.target.value.trim() !== '') {
      if (parseInt(inputElement.style.width, 10) < 15) inputElement.style.width = '15ch'
    }
  }

  change(event) {
    this.showhideTarget.classList.add('hidden')

    let key = event.target.value
    let options = this.options[key]

    if (options && options.length > 0) {
      this.setOptions(options)
      this.showhideTarget.classList.remove('hidden')
    }
    this.change_level_sub_level()
  }

  change_level_sub_level() {
    const level = document.getElementById('user_level').value
    const subLevel = document.getElementById('user_sub_level')
    const subLevelLabel = document.querySelector('label[for="user_sub_level"]')

    if (subLevel) {
      if (level === 'rldr') {
        subLevel.style.display = 'block'
        subLevelLabel.style.display = 'block'
      } else {
        subLevel.style.display = 'none'
        subLevelLabel.style.display = 'none'
        subLevel.selectedIndex = 0
      }
    }
  }
  get options() {
    return JSON.parse(this.element.getAttribute('data-options'))
  }

  setOptions(data) {
    this.elementChoices.clearStore()
    this.elementChoices.setChoices(data, 'id', 'name', true)
  }
}
