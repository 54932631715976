import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["element", "radio"]

  connect() {
    if(typeof this.radioTarget != 'undefined') {
      
      this.notifyText = this.element.querySelector("#notify-text")
      this.notifyForm = this.element.querySelector("#notify-form")
      
      this.notifyForm.style.display = 'none'

      this.send_printed_copy()
    }
  }

  send_printed_copy() {
    
    this.sendPrintedCopyInputChecked = this.radioTarget.querySelector('input[name="complaint[send_printed_copy]"]:checked')

    if(this.sendPrintedCopyInputChecked){

      var key = this.sendPrintedCopyInputChecked.getAttribute("data-key");

      switch (key) {
        case "to_email":
          this.email();
          break;
        case "to_phone":
          this.phone();
          break;
        default:
          this.other();
          break;
      }
    }
  }

  email() {
    this.notifyText.innerHTML = 'Quiero recibir notificaciones de avances de mi reclamo en mi correo.'
    this.notifyForm.style.display = 'none'
  }

  phone() {
    this.notifyText.innerHTML = 'Quiero recibir notificaciones de avances de mi reclamo en mi celular.'
    this.notifyForm.style.display = 'none'
  }

  other() {
    this.notifyText.innerHTML = 'Quiero recibir notificaciones de avances de mi reclamo.'
    this.notifyForm.style.display = ''
  }
}

export function handleRadioButtonChange(value) {

  let notifyText = document.querySelector("#notify-text")
  let notifyForm = document.querySelector("#notify-form")

  switch (value) {
    case "to_email":
      notifyText.innerHTML = 'Quiero recibir notificaciones de avances de mi reclamo en mi correo.'
      notifyForm.style.display = 'none'
      break;
    case "to_phone":
      notifyText.innerHTML = 'Quiero recibir notificaciones de avances de mi reclamo en mi celular.'
      notifyForm.style.display = 'none'
      break;
    default:
      notifyText.innerHTML = 'Quiero recibir notificaciones de avances de mi reclamo.'
      notifyForm.style.display = ''
      break;
  }
}
