import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["element"];

  toggle(event) {
    let route_files = {
      organic_units: {
        route: "/formatos/Formato_unidadesorganicas.xlsx",
        text: "Formato Unidades.xlsx",
      },
      branches: {
        route: "/formatos/Formato_sedes.xlsx",
        text: "Formato Sedes.xlsx",
      },
      users: {
        route: "/formatos/Formato_registro_usuarios.xlsx",
        text: "Formato Usuarios.xslx",
      },
    };
    this.elementTarget.href = route_files[event.target.value].route;
    this.elementTarget.lastChild.textContent =
      route_files[event.target.value].text;
    this.elementTarget.classList.remove("hidden");
  }
}
