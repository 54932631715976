import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import Choices from 'choices.js'

export default class extends Controller {
  static targets = [
    'institution',
    'branches',
    'hasNoBranch',
    'children',
    'childrenWrapper',
    'parentOrChild',
    'parentOrChildLabel',
    'parentOrChildOptions',
    'parentLabel'
  ]

  connect() {
    this.branchesTarget.disabled = false
    this.elementChoices = new Choices(this.branchesTarget)
    this.childPlaceholder = { id: '', name: 'Seleccione una subsede', placeholder: true, selected: true }
    this.childrenChoices = new Choices(this.childrenTarget)

    if (!!this.branchesTarget.value && this.branchesTarget.value != '' && !!localStorage.getItem('childrenData')) {
      const data = JSON.parse(localStorage.getItem('childrenData'))
      this.refreshChildrenDropdownValues(data)
      const childSelected = localStorage.getItem('childSelected')
      if (!!childSelected) this.childrenChoices.setChoiceByValue(+childSelected)
    } else {
      localStorage.removeItem('childSelected')
      localStorage.removeItem('childrenData')
    }
  }

  updateChildren(event) {
    const institutionId = this.institutionTarget.value
    const branchId = event.target.value
    if (branchId && institutionId) {
      let ajax_url = event.target.getAttribute('data-ajax-url')
      ajax_url = ajax_url.replace(/:parent_id:/g, institutionId)
      const uri = new URL(ajax_url, window.location.origin)
      uri.searchParams.append('branch_id', branchId)

      Rails.ajax({
        type: 'GET',
        url: uri.href,
        success: data => {
          if (data.length > 0) {
            this.showParentOrChild(event)
            console.log(data)
            localStorage.setItem('childrenData', JSON.stringify(data))
          } else {
            this.resetChildrenFlow()
          }
        },
        error: () => {
          this.resetChildrenFlow()
        }
      })
    }
  }

  update(event) {
    let id = event.target.value
    console.log("id es: " +id)
    if (id) {
      let ajax_url = event.target.getAttribute('data-ajax-url')
      ajax_url = ajax_url.replace(/:parent_id:/g, id)

      this.branchesTarget.disabled = true
      this.branchesTarget.innerHTML = '<option value=""> Cargando sedes... </option>'

      Rails.ajax({
        type: 'GET',
        url: ajax_url,
        success: data => {
          this.refreshDropdownValues(data)
        },
        error: () => {
          this.branchesTarget.innerHTML = '<option value=""> Seleccione sedes: </option>'
        }
      })
    } else {
      this.branchesTarget.disabled = true

      const placeholder = [
        {
          id: '',
          name: 'Seleccione sede',
          placeholder: true,
          selected: true,
          disabled: true
        }
      ]
      this.elementChoices.clearStore()
      this.elementChoices.setChoices(placeholder, 'id', 'name', true).disable()
    }
  }

  refreshDropdownValues(data) {
    console.log("data es: " + data)
    if (!this.hasNoBranchTarget.checked) {
      this.branchesTarget.disabled = false
    }

    const placeholder = {
      id: '',
      name: 'Seleccione sede',
      placeholder: true,
      selected: true
    }
    data.unshift(placeholder)
    this.elementChoices.clearStore()
    this.elementChoices.setChoices(data, 'id', 'name', true).enable()
    this.resetChildrenFlow()
  }

  resetChildrenFlow() {
    localStorage.removeItem('childrenData')
    this.parentOrChildTarget.classList.add('hidden')
    this.parentOrChildOptionsTargets.forEach(option => (option.checked = false))
    this.clearChildrenDropdown()
  }

  clearChildrenDropdown() {
    this.childrenChoices.clearStore()
    this.childrenWrapperTarget.classList.add('hidden')
  }

  refreshChildrenDropdownValues(data) {
    this.childrenChoices.clearStore()
    data = [this.childPlaceholder, ...data]
    this.childrenChoices.setChoices(data, 'id', 'name', true).enable()
    this.childrenWrapperTarget.classList.remove('hidden')
  }

  hideBranch(event) {
    let checkedValue = event.target.checked
    this.branchesTarget.value = ''

    if (checkedValue) {
      this.elementChoices.setChoices().disable()
      this.childrenChoices.setChoices().disable()
      this.parentOrChildOptionsTargets.forEach(option => (option.disabled = true))
      return (this.branchesTarget.disabled = true)
    }
    this.branchesTarget.disabled = false
    this.elementChoices.setChoices().enable()
    this.childrenChoices.setChoices().enable()
    this.parentOrChildOptionsTargets.forEach(option => (option.disabled = false))
  }

  showParentOrChild(event) {
    const target = event.target
    const selectedBranch = target.options[target.selectedIndex].text
    this.parentOrChildLabelTarget.innerHTML = `¿La situación ocurrió en la sede "${selectedBranch}" o en una de sus subsedes?`
    this.parentLabelTarget.innerHTML = `En la sede "${selectedBranch}"`
    this.parentOrChildTarget.classList.remove('hidden')
  }

  showHideChildrenBranch(event) {
    const value = event.target.value
    if (value === '0') {
      this.clearChildrenDropdown()
    } else if (value === '1') {
      this.refreshChildrenDropdownValues(JSON.parse(localStorage.getItem('childrenData')))
    }
  }

  updateChildSelected(event) {
    const value = event.target.value
    if (!!value) localStorage.setItem('childSelected', value)
  }
}
