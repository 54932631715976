import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element"]

  toggle(event) {
    const item = event.target
    const show = item.dataset.show
    if( show ){
      const value = item.value
      this.elementTargets.forEach((el, i) => {
        if( show === value ){
          el.classList.remove("hidden")
        } else {
          el.classList.add("hidden")
        }
      })
    } else {
      this.elementTargets.forEach((el, i) => {
        el.classList.toggle("hidden")
      })
    }
  }
}