import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];
  confirm(e) {
    if (this.hasButtonTarget) {
      this.buttonTargets.forEach((button) => {
        if (button !== e.currentTarget && button.disabled == true) {
          button.classList.add("hidden");
        }
      });
    }
  }
}
