import Rails from '@rails/ujs'

document.addEventListener('turbolinks:load', event => {
  let miSede = document.querySelector('.js-sede')
  if (miSede) {
    let miSedeValor = miSede.value

    miSede.addEventListener('change', event => {
      if (!event.target.matches('.js-submit-on-click-sede')) {
        return
      }
      if (confirm(`¿Estás seguro que quieres derivar la ${event.target.options[event.target.selectedIndex].text}?`)) {
        Rails.fire(event.target.closest('form'), 'submit')
      } else {
        event.preventDefault()
        event.target.value = miSedeValor
      }
    })
  }
})
