import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["input", "date", "radioInputs", "submitButton"];

  connect() {
    this.validateForm();
  }

  validateForm() {
    this.submitButtonTarget.disabled = !(this.isInputComplete() && this.isDateComplete() && this.isRadioSelected());
  }

  isInputComplete() {
    if (!this.hasInputTarget) return true;
    return [...this.inputTargets].every((input) => input.value !== "");
  }

  isDateComplete() {
    if (!this.hasDateTarget) return true;
    return [...this.dateTargets].every((input) => input.querySelector('input[type="date"]').value !== "");
  }

  isRadioSelected() {
    if (!this.hasRadioInputsTarget) return true;
    return [...this.radioInputsTargets].some((input) => input.checked);
  }

  inputChanged() {
    this.validateForm();
  }

  dateChanged() {
    this.validateForm();
  }

  radioChanged() {
    this.validateForm();
    this.toggleDateSection();
  }

  toggleDateSection() {
    const typeReportField = this.radioInputsTargets.find(radioButton => radioButton.checked);
    const dateSection = this.element.querySelector('#date_section');

    const hideDateSection = typeReportField && typeReportField.value === 'responsables';
    dateSection.style.display = hideDateSection ? 'none' : 'block';
    this.submitButtonTarget.disabled = hideDateSection ? false : true;
  }
}