import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["section"];

  toggle(event) {
    this.sectionTargets.forEach((el, i) => {
      el.classList.remove("hidden");
    });
    if (event.target.checked) {
      this.sectionTargets.forEach((el, i) => {
        if (
          el.getAttribute("data-key") ==
          event.target.getAttribute("data-notify")
        ) {
          el.classList.add("hidden");
        }
          
      });
    }
  }
}
