import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element"]

  connect(){
    console.log("connected show-hide")
  }

  toggle(event) {

    console.log("call show-hide toggle")
    
    this.elementTargets.forEach((el, i) => {
      el.classList.add("hidden")
    })
    
    this.elementTargets.forEach((el, i) => {
      console.log(el.getAttribute('data-key'))
      if (el.getAttribute('data-key') == event.target.value)
        el.classList.remove("hidden")
        el.querySelectorAll("select").forEach((elc, i) =>{ 
          elc.value = null
        })
    })
    
  }
}