import Rails from "@rails/ujs"
import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ['element']
  submit(event) {
    console.log("ingresando aqui")
    const form = event.target.closest('form')
    form.submit()
  }

  railsSubmit(event) {
    console.log("el evento es" + event)
    const form = event.target.closest('form')
    form.addEventListener('ajax:beforeSend', event => {
      const [xhr, options] = event.detail
      window.filters_timestamp = new Date().getTime()
      xhr.setRequestHeader('timestamp', window.filters_timestamp)
      Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(options.url, Turbolinks.uuid())
    })
    Rails.fire(form, this.submit(event))
  }

  //solo usado para el cambio de parametros en la URL para responder por multiples UO
  locationChange(){
    const event = this.elementTarget.dataset.event
    const cou_id= this.elementTarget.value
    window.location= `?cou_id=${cou_id}&set_organic_unit_event=${event}`
  }


}